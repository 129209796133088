import {
	ApolloClientOptions,
	ApolloLink,
	createHttpLink,
	from,
	Operation,
	PossibleTypesMap,
	split
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { Kind, OperationTypeNode } from 'graphql';
import { createClient } from 'graphql-ws';
import { firstValueFrom, map, Observable } from 'rxjs';

import { ILogger } from '@shure/shared/angular/utils/logging';

import { ApolloCacheFactory } from './apollo-cache-factory.service';
import { ApolloConnectInfo } from './apollo-connection.models';
import { ApolloConnectionService } from './apollo-connection.service';

export abstract class HttpApolloConnectionService extends ApolloConnectionService {
	private retryCountLastSeenOk = 0;
	private retryCountLastSeen = 0;

	private readonly logger: ILogger;
	private apolloConnectInfo$: Observable<ApolloConnectInfo>;

	constructor(
		logger: ILogger,
		private readonly name: string,
		private readonly possibleTypes: PossibleTypesMap,
		private readonly retryCountMax: number,
		private readonly connectInfoProvider$: Observable<{ url: string; apiKey: string }>,
		private readonly apolloCacheFactory: ApolloCacheFactory,
		private readonly apolloLinks: ApolloLink[]
	) {
		super();
		this.logger = logger.createScopedLogger(`ApolloConnectionService.${this.name}`);
		this.apolloConnectInfo$ = this.connectInfoProvider$.pipe(
			map((connectInfo: { url: string; apiKey: string }) => {
				const apolloConnectInfo = {
					gqlHttpUrl: connectInfo.url,
					gqlWsUrl: connectInfo.url.replace('https', 'wss').replace('http', 'ws'),
					apiKey: connectInfo.apiKey
				};
				return apolloConnectInfo;
			})
		);
		this.doResetRetryCount();
	}

	public createApolloClientOptions(): ApolloClientOptions<unknown> {
		return {
			cache: this.apolloCacheFactory.createCache(this.name, this.possibleTypes),
			link: from([...this.apolloLinks, this.createLink()])
		};
	}
	private createLink(): ApolloLink {
		this.logger.trace('createLink()', '', { name: this.name });

		const contextConnectInfo = setContext(async () => {
			this.logger.trace('withConnectInfo', 'waiting');
			const connectInfo = await firstValueFrom(this.apolloConnectInfo$);
			this.logger.trace('withConnectInfo', 'available', { connectInfo });
			return { connectInfo };
		});

		// If data is coming back, we has verified that the connection to sysapi server is ok
		// and we can reset the retry counter.
		const successLink = new ApolloLink((operation, forward) => {
			return forward(operation).map((data) => {
				this.doResetRetryCount();
				return data;
			});
		});

		const errorLink = onError(({ networkError }) => {
			if (networkError) {
				// Catches http error codes, we are ignore this for now,
				// but could indicate authentication issues if used properly on server side
				if ('statusCode' in networkError) {
					this.logger.trace('errorLink', 'httpError', { statusCode: networkError.statusCode });
					return;
				}
				this.logger.trace('errorLink', 'networkError', { networkError: JSON.stringify(networkError) });
				this.onNetworkError();
			}
		});

		const authMiddlewareLink = new ApolloLink((operation, forward) => {
			const { connectInfo } = operation.getContext();
			operation.setContext(() => ({
				headers: {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'x-api-key': connectInfo.apiKey
				}
			}));
			return forward(operation);
		});

		// split operation based on type. Queries and mutations use http and
		// subscriptions use websockets
		const connectLink = split(
			({ query }) => {
				const definition = getMainDefinition(query);
				return (
					definition.kind === Kind.OPERATION_DEFINITION &&
					definition.operation === OperationTypeNode.SUBSCRIPTION
				);
			},
			new GraphQLWsLink(
				createClient({
					url: () => firstValueFrom(this.apolloConnectInfo$).then((connectInfo) => connectInfo.gqlWsUrl)
				})
			),
			createHttpLink({
				uri: (operation) => {
					const { connectInfo } = operation.getContext();
					return connectInfo.gqlHttpUrl;
				}
			})
		);
		return from([successLink, errorLink, contextConnectInfo, authMiddlewareLink, connectLink]);
	}

	private onNetworkError(): void {
		this.logger.trace('onNetworkError', '');
		// Currently we are not doing anything, but this indicate that the connection is broken,
		// and we should show this to the user to take action.
	}

	private shouldRetry(count: number, operation: Operation, error: unknown): boolean {
		// count comes from Apollo RetryLink and seems to occasionally being reset,
		// but the reset is not related to e.g. successfully subscriptions
		this.retryCountLastSeen = count;

		const retryCount = this.getRetryCount();
		this.logger.trace('shouldRetry', '', {
			retryCount,
			retryCountLastSeen: this.retryCountLastSeen,
			retryCountLastSeenOk: this.retryCountLastSeenOk,
			count,
			operation,
			error
		});
		return retryCount < this.retryCountMax;
	}

	private getRetryDelay(_count: number): number {
		return 2000 * Math.random();
	}

	private doResetRetryCount(): void {
		this.retryCountLastSeenOk = this.retryCountLastSeen;
	}

	private getRetryCount(): number {
		return Math.max(0, this.retryCountLastSeen - this.retryCountLastSeenOk);
	}
}
