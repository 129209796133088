import { Injectable, Inject } from '@angular/core';
import { catchError, map, Observable, Subject, Subscription, takeUntil, tap, throwError } from 'rxjs';

import { SubscriptionManager, SubscriptionManagerConfigCreate } from '@shure/cloud/shared/apollo';
import { FirmwarePackage, PropertyPanelDevice } from '@shure/cloud/shared/models/devices';
import { UpdateResponse } from '@shure/cloud/shared/models/http';
import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { ApolloQueryErrorMapper } from '@shure/shared/angular/data-access/system-api/core';
import { ILogger } from '@shure/shared/angular/utils/logging';

import {
	AssociateTagMutationResult,
	CloudDeviceApiService,
	DissociateTagMutationResult
} from '../api/cloud-device-api.service';
import { DevicePropertyPanelApiService } from '../api/device-property-panel-api.service';

import {
	PropertyPanelDeviceQueryGQL,
	PropertyPanelDeviceSubscriptionGQL,
	NodeChangeType,
	PropertyPanelDeviceFragment
} from './graphql/generated/cloud-sys-api';
import { CloudPropertyPanelRfChannelLinkedTransmitterSubscriptionGQL } from './graphql/generated/cloud-sys-api';
import { mapPropertyPanelDeviceFromSysApi } from './mappers/map-property-panel-device';

@Injectable({ providedIn: 'root' })
export class SysApiDevicePropertyPanelApiService extends DevicePropertyPanelApiService {
	private destroy$ = new Subject<void>();
	private readonly logger: ILogger;

	private readonly selectedDeviceSubscription = new SubscriptionManager({
		subscriptionType: 'property-panel',
		create: (config): Subscription => this.createDeviceSubscription(config),
		retryWaitMs: 5000,
		maxRetryAttempts: 3
	});

	private readonly linkedTransmitterSubscription = new SubscriptionManager({
		subscriptionType: 'property-panel',
		create: (config): Subscription => this.createLinkedTransmitterSubscription(config),
		retryWaitMs: 5000,
		maxRetryAttempts: 3
	});

	private readonly rfChannelSubscriptions = new SubscriptionManager({
		subscriptionType: 'property-panel',
		create: (config): Subscription => this.createRfChannelSubscription(config),
		retryWaitMs: 5000,
		maxRetryAttempts: 3
	});

	constructor(
		logger: ILogger,
		private readonly cloudDeviceService: CloudDeviceApiService,
		private readonly propertyPanelDeviceQueryGQL: PropertyPanelDeviceQueryGQL,
		private readonly propertyPanelDeviceSubscriptionGQL: PropertyPanelDeviceSubscriptionGQL,
		private readonly cloudPropPanelRfChannelGQL: CloudPropertyPanelRfChannelLinkedTransmitterSubscriptionGQL,
		private readonly oktaService: OktaInterfaceService,
		@Inject(APP_ENVIRONMENT) private readonly appEnv: AppEnvironment
	) {
		super();
		this.logger = logger.createScopedLogger('DaiDevicePropertyPanelService');

		monitorLoginState(this.oktaService, {
			onLogIn: this.initService,
			onLogOut: this.suspendService
		});
	}

	/**
	 * Get device by id
	 * @param deviceId
	 * @returns
	 */
	public getDevice$(deviceId: string): Observable<PropertyPanelDevice> {
		this.logger.trace('getDevice$()', 'propertyPanelDeviceQueryGQL', { deviceId });

		return this.propertyPanelDeviceQueryGQL
			.watch(
				{
					nodeId: deviceId,
					requestFirmwareFields: this.appEnv.showFirmwareUpgrade ?? false,
					requestProxiedDevices: !!this.appEnv.showProxiedDevices,
					requestTags: this.appEnv.showTags ?? false,
					requestReboot: this.appEnv.cdmFeatureFlags?.showReboot ?? false
				},
				{
					fetchPolicy: 'cache-and-network',
					errorPolicy: 'ignore',
					returnPartialData: true
				}
			)
			.valueChanges.pipe(
				map((query) => {
					if (query.data.node && 'isDeviceNode' in query.data.node) {
						const device = mapPropertyPanelDeviceFromSysApi(query.data.node);

						const { rfChannelIds, linkedTransmitterIds } = this.getLinkedTransmitterIds(query.data.node);
						return { device, rfChannelIds, linkedTransmitterIds };
					}
					this.logger.error(
						'getDevice$()',
						'Failed to query propertypanel device',
						JSON.stringify({ deviceId })
					);
					throw ApolloQueryErrorMapper.getError(query);
				}),
				// establish the per-device nodeChanges subscription, if not already established.
				tap(({ device, rfChannelIds, linkedTransmitterIds }) => {
					this.selectedDeviceSubscription.register([device.id]);
					this.logger.debug('propPanelEvent', 'new rfChannelIds/linkedTransmitterIds', {
						rfChannelIds,
						linkedTransmitterIds
					});
					// Create Subscriptions for each of the rfChannels and their
					// linked transmitters.  The last arg to 'register' tells the
					// subscription manager to remove subscriptions for ids that are different
					// than the prior set.
					this.rfChannelSubscriptions.register(rfChannelIds, true);
					this.linkedTransmitterSubscription.register(linkedTransmitterIds, true);
				}),
				map(({ device }) => device),
				tap((device) => this.logger.debug('propPanel device', 'new device', { device })),
				catchError((error: Error) => {
					this.logger.error(
						'getDevice$()',
						'Failed to query property panel device',
						JSON.stringify({
							deviceId,
							error
						})
					);
					return throwError(() => error);
				})
			);
	}

	/**
	 * Tell the service to "forget" about this device. Includes unsubscribing to
	 * the more detailed property subscriptions.
	 * @param nodeId
	 */
	public forgetDevice(nodeId: string): void {
		this.selectedDeviceSubscription.deregister(nodeId);
		this.rfChannelSubscriptions.deregisterAll();
		this.linkedTransmitterSubscription.deregisterAll();
	}

	/**
	 * Set mute for a device.
	 * @param deviceId
	 * @param mute
	 * @returns
	 */
	public setMute(deviceId: string, mute: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setMute()', 'Setting mute', { deviceId, mute });
		return this.cloudDeviceService.setMute(deviceId, mute);
	}

	/**
	 * Set device name.
	 * @param deviceId
	 * @param name
	 * @returns
	 */
	public setDeviceName(deviceId: string, name: string): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setDeviceName()', 'Setting device name', JSON.stringify({ deviceId, name }));
		return this.cloudDeviceService.setDeviceName(deviceId, name);
	}

	/**
	 * Set device identifying state.
	 * @param deviceId
	 * @param identify
	 * @returns
	 */
	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setIdentify()', 'Setting identify', { deviceId, identify });
		return this.cloudDeviceService.setIdentify(deviceId, identify);
	}

	/**
	 * Reboot a device
	 * @param deviceId
	 * @returns
	 */
	public rebootDevice(deviceId: string): Observable<UpdateResponse<void, string>> {
		this.logger.trace('rebootDevice()', 'Rebooting device', { deviceId });
		return this.cloudDeviceService.rebootDevice$(deviceId);
	}

	/**
	 * Request a device firmware update
	 * @param deviceId
	 * @param firmwarePkg
	 * @returns
	 */
	public override updateFirmware(
		deviceId: string,
		firmwarePkg: FirmwarePackage
	): Observable<UpdateResponse<void, string>> {
		this.logger.trace('updateFirmware()', 'Updating firmware', { deviceId, package: firmwarePkg });
		return this.cloudDeviceService.updateFirmware$([
			{ id: deviceId, firmwarePackageKey: firmwarePkg.key, firmwarePackageVersion: firmwarePkg.version }
		]);
	}

	public override associateTag(deviceIds: string[], tag: string): Observable<AssociateTagMutationResult> {
		return this.cloudDeviceService.associateTag(deviceIds, tag);
	}

	public override dissociateTag(deviceIds: string[], tag: string): Observable<DissociateTagMutationResult> {
		return this.cloudDeviceService.dissociateTag(deviceIds, tag);
	}

	private initService = (): void => {
		this.logger.information('initService', 'user logged in, initializating service');
		this.destroy$ = new Subject();
	};

	private suspendService = (): void => {
		this.logger.information('suspendService', 'user logged out, suspending service');
		this.destroy$.next();
		this.destroy$.complete();
		this.selectedDeviceSubscription.deregisterAll();
	};

	private createDeviceSubscription({ id, retryCallback }: SubscriptionManagerConfigCreate): Subscription {
		const subscriptionTypes = [
			NodeChangeType.DeviceAudioChannelCount,
			NodeChangeType.DeviceAudioMute,
			NodeChangeType.DeviceAvailablePackages,
			NodeChangeType.DeviceDanteAudioNetwork,
			NodeChangeType.DeviceBatteryLevel,
			NodeChangeType.DeviceIdentify,
			NodeChangeType.DeviceLicense,
			NodeChangeType.DeviceLicenseV2,
			NodeChangeType.DeviceMicStatus,
			NodeChangeType.DeviceName,
			NodeChangeType.DeviceUptime,
			NodeChangeType.DeviceTags
		];

		return this.propertyPanelDeviceSubscriptionGQL
			.subscribe(
				{
					id,
					types: subscriptionTypes,
					requestFirmwareFields: this.appEnv.showFirmwareUpgrade ?? false,
					requestProxiedDevices: !!this.appEnv.showProxiedDevices,
					requestTags: this.appEnv.showTags ?? false,
					requestReboot: this.appEnv.cdmFeatureFlags?.showReboot ?? false
				},
				{ fetchPolicy: 'network-only' }
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (change) => {
					this.logger.trace('propPanelDevice (selectedDevice)', 'Updated', { id, change });
				},
				complete: () => {
					this.logger.debug('propPanelDevice (selectedDevice)', 'Completed', { id });
				},
				error: (error) => {
					this.logger.error(
						'propPanelDevice (selectedDevice)',
						'Encountered error',
						JSON.stringify({ id, error })
					);
					retryCallback();
				}
			});
	}

	private createLinkedTransmitterSubscription({ id, retryCallback }: SubscriptionManagerConfigCreate): Subscription {
		const subscriptionTypes = [
			NodeChangeType.DeviceAudioMute,
			NodeChangeType.DeviceBatteryLevel,
			NodeChangeType.DeviceIdentify,
			NodeChangeType.DeviceMicStatus,
			NodeChangeType.DeviceName
		];

		return this.propertyPanelDeviceSubscriptionGQL
			.subscribe(
				{
					id,
					types: subscriptionTypes,
					requestFirmwareFields: this.appEnv.showFirmwareUpgrade ?? false,
					requestProxiedDevices: !!this.appEnv.showProxiedDevices,
					requestTags: this.appEnv.showTags ?? false,
					requestReboot: this.appEnv.cdmFeatureFlags?.showReboot ?? false
				},
				{ fetchPolicy: 'network-only' }
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (change) => {
					this.logger.trace('propPanelDevice (linked xmitter)', 'Updated', { id, change });
				},
				complete: () => {
					this.logger.debug('propPanelDevice (linked xmitter)', 'Completed', { id });
				},
				error: (error) => {
					this.logger.error(
						'propPanelDevice (linked xmitter)',
						'Encountered error',
						JSON.stringify({ id, error })
					);
					retryCallback();
				}
			});
	}

	private createRfChannelSubscription({ id, retryCallback }: SubscriptionManagerConfigCreate): Subscription {
		const subscriptionTypes = [NodeChangeType.RfChannelLinkedTransmitter];

		return this.cloudPropPanelRfChannelGQL
			.subscribe(
				{
					rfChannelNodeId: id,
					rfChannelNodeChangeType: subscriptionTypes
				},
				{ fetchPolicy: 'network-only' }
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (change) => {
					this.logger.trace('propPanelRfChannel', 'Received update', { id, change });
				},
				complete: () => {
					this.logger.debug('propPanelRfChannel', 'Completed', { id });
				},
				error: (error) => {
					this.logger.error('propPanelRfChannel', 'Encountered error', JSON.stringify({ id, error }));
					retryCallback();
				}
			});
	}

	private getLinkedTransmitterIds(fragmentData: PropertyPanelDeviceFragment): {
		rfChannelIds: string[];
		linkedTransmitterIds: string[];
	} {
		const rfChannelIds: string[] = [];
		const linkedTransmitterIds: string[] = [];

		const rfChannels = fragmentData.features.rfChannels?.rfChannels;
		if (rfChannels) {
			for (const rfChannel of rfChannels) {
				rfChannelIds.push(rfChannel.id);
				const linkage = rfChannel.features.linkedTransmitter?.linkage;
				if (linkage?.device?.id) {
					linkedTransmitterIds.push(linkage.device.id);
				}
			}
		}
		return { rfChannelIds, linkedTransmitterIds };
	}
}
