import { memoize } from './operators/memoize';
import { identity } from '../util/identity';
import { isFunction } from '../util/isiterable';
import { AsyncSink } from './asyncsink';
const { isArray } = Array;
function callOrApply(fn, args) {
    return isArray(args) ? fn(...args) : fn(args);
}
/**
 * Creates async-iterable from an event emitter by adding handlers for both listening and unsubscribing from events.
 *
 * @template TSource The type of elements in the event emitter.
 * @param {(handler: (...args: any[]) => void) => void} addHandler The function to add a listener to the source.
 * @param {(handler: (...args: any[]) => void) => void} removeHandler The function to remove a listener from the source.
 * @returns {AsyncIterableX<TSource>} An async-iterable which contains the data from the underlying events as wrapped by the handlers.
 */
export function fromEventPattern(addHandler, removeHandler, resultSelector) {
    if (!isFunction(resultSelector)) {
        resultSelector = identity;
    }
    const sink = new AsyncSink();
    const handler = (...args) => sink.write(callOrApply(resultSelector, args));
    addHandler(handler);
    const loop = async function* () {
        try {
            for (let next; !(next = await sink.next()).done;) {
                yield next.value;
            }
        }
        finally {
            removeHandler(handler);
            sink.end();
        }
    };
    return memoize()(loop());
}


