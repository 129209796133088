import { TypedDocumentNode } from 'apollo-angular';
import {
	DocumentNode,
	FieldNode,
	FragmentDefinitionNode,
	FragmentSpreadNode,
	InlineFragmentNode,
	Kind,
	OperationDefinitionNode,
	OperationTypeNode,
	SelectionNode
} from 'graphql';

export interface SubscribedField {
	name: string;
	alias?: string;

	/**
	 * Direct child fields subscribed to on this field
	 */
	fields: SubscribedField[];

	/**
	 * Direct child fragments subscribed on this field (undefined if there are no fragments here)
	 */
	fragments: SubscribedFragment[];
}

export interface SubscribedFragment extends SubscribedField {
	/**
	 * The name of the type this fragment is on. I.e. the <type> part of "... on <type> { fields }" or "fragment <fragmentName> on <type>"
	 */
	typeName: string;

	/**
	 * Is the fragment a field-local inline fragment?
	 */
	isInline: boolean;
}

export interface ParsedSubscription {
	name: string;

	/**
	 * The top-level subscribed field (e.g. 'nodeChanges(id, changeTypes)')
	 */
	selection: SubscribedField;
}

export type GqlSubscriptionDocument = DocumentNode | TypedDocumentNode<unknown, unknown>;

export class GqlSubscriptionParser {
	public static parseGqlSubscription(gqlDocument: GqlSubscriptionDocument): ParsedSubscription {
		const fragmentMap = new Map<string, SubscribedFragment>();

		const operation = this.getGqlOperation(gqlDocument);
		if (!operation) {
			throw new Error('GQL is not a subscription');
		}
		const operationField = this.getGqlOperationField(operation);
		if (!operationField) {
			throw new Error('GQL has no operation field to subscribe to');
		}

		const fragmentNodes = this.getGqlOperationFragments(gqlDocument);
		if (fragmentNodes) {
			// Populate the fragment map with empty shells (to support inter-fragment referencing while parsing)
			fragmentNodes.forEach((f) =>
				fragmentMap.set(f.name.value, {
					name: f.name.value,
					alias: undefined,
					typeName: f.typeCondition.name.value,
					isInline: false,
					fields: [],
					fragments: []
				})
			);

			// Actually parse the fragments
			fragmentNodes.forEach((f) => {
				const fragment = fragmentMap.get(f.name.value);
				if (fragment && f.selectionSet.selections) {
					this.getGqlExplicitChildFieldTree(f.selectionSet.selections, fragmentMap, fragment);
				}
			});
		}

		// Parse the GQL field selections
		return {
			name: operation.name?.value ?? '',
			selection: this.getGqlExplicitFieldTree(operationField, fragmentMap)
		};
	}

	/**
	 * Get the operation definition of an Apollo GQL subscription definition instance
	 */
	private static getGqlOperation(gqlDocument: GqlSubscriptionDocument): OperationDefinitionNode | undefined {
		const operation = <OperationDefinitionNode>(
			gqlDocument.definitions.find((def) => def.kind === Kind.OPERATION_DEFINITION)
		);
		if (!operation || operation.operation !== OperationTypeNode.SUBSCRIPTION) {
			return undefined;
		}
		return operation;
	}

	/**
	 * Get the GQL operation field from a subscription
	 */
	private static getGqlOperationField(operation: OperationDefinitionNode): FieldNode | undefined {
		const operationField = operation.selectionSet?.selections[0];
		if (!operationField || operationField.kind !== Kind.FIELD) {
			return undefined;
		}
		return operationField;
	}

	/**
	 * Collect all fragments registered with the GQL operation.
	 */
	private static getGqlOperationFragments(
		gqlDocument: GqlSubscriptionDocument
	): Map<string, FragmentDefinitionNode> | undefined {
		const fragmentDefs = gqlDocument.definitions
			.filter((def) => def.kind === Kind.FRAGMENT_DEFINITION)
			.map((def) => <FragmentDefinitionNode>def);

		const fragmentMap = new Map<string, FragmentDefinitionNode>();
		fragmentDefs.forEach((def) => fragmentMap.set(def.name.value, def));

		return fragmentMap;
	}

	/**
	 * Extract the tree of fields subscribed by the passed in parentFieldName
	 */
	private static getGqlExplicitFieldTree(
		parentFieldNode: FieldNode,
		fragmentMap: Map<string, SubscribedFragment>
	): SubscribedField {
		const rootField: SubscribedField = {
			name: parentFieldNode.name.value,
			alias: parentFieldNode.alias?.value,
			fields: [],
			fragments: []
		};

		if (parentFieldNode.selectionSet) {
			this.getGqlExplicitChildFieldTree(parentFieldNode.selectionSet.selections, fragmentMap, rootField);
		}

		return rootField;
	}

	/**
	 * Extract the tree of fields subscribed by the passed in parentFieldName
	 */
	private static getGqlExplicitChildFieldTree(
		fieldList: readonly SelectionNode[],
		fragmentMap: Map<string, SubscribedFragment>,
		outputItem: SubscribedField
	): void {
		for (const item of fieldList) {
			switch (item.kind) {
				case Kind.FIELD:
					outputItem.fields.push(this.getGqlExplicitFieldTree(<FieldNode>item, fragmentMap));
					break;

				case Kind.FRAGMENT_SPREAD: {
					const fragmentItem = <FragmentSpreadNode>item;
					const fragmentName = fragmentItem.name.value;
					const fragment = fragmentMap.get(fragmentName);
					if (!fragment) {
						throw new Error(
							`Fragment ${fragmentName} used for selection in field ${outputItem.name} is not defined`
						);
					}
					outputItem.fragments.push(fragment);
					break;
				}

				case Kind.INLINE_FRAGMENT: {
					const fragmentItem = <InlineFragmentNode>item;
					const fragment: SubscribedFragment = {
						name: `__inline__${fragmentItem.typeCondition?.name.value ?? 'untyped'}`,
						alias: undefined,
						typeName: fragmentItem.typeCondition?.name.value ?? '',
						isInline: true,
						fields: [],
						fragments: []
					};
					this.getGqlExplicitChildFieldTree(fragmentItem.selectionSet.selections, fragmentMap, fragment);

					outputItem.fragments.push(fragment);
					break;
				}
			}
		}
	}
}
