import { Subscription as ApolloSubscription, SubscriptionResult } from 'apollo-angular';
import { Subscription } from 'rxjs';

import { ILogger } from '@shure/shared/angular/utils/logging';

import { NodeChangeType } from '../../generated/system-api.generated';

import { SubscriptionHandler, SubscriptionManagerService } from './subscription-manager-service';

export type GqlSubscriptionVariables = {
	id: string;
	types: NodeChangeType[] | NodeChangeType;
};

/**
 * Configuration to pass when creating a subscription manager instance
 */
export interface CombiningSubscriptionManagerConfig<TResult = unknown> {
	/**
	 * Name of the object instantiating the subscription manager (for logging and debugging purposes only).
	 * E.g. "RoomSysApiService:Room" for RoomSysApiService when subscribing for room changes.
	 */
	name: string;

	/**
	 * The changes types this susbcription manager is to subscribe to on targets
	 */
	changeTypes: NodeChangeType[];

	/**
	 * The GQL subscription creation instance to use when setting up a subscription on a target
	 */
	subscriptionGql: ApolloSubscription<TResult, GqlSubscriptionVariables>;

	/**
	 * The initial retry delay when wanting to retry a failed subscription.
	 * If not specified an internal default will be used.
	 */
	retryWaitMs?: number;

	/**
	 * Callback invoked when a subscription receives update data
	 */
	onUpdate?: (targetId: string, update: SubscriptionResult<TResult>) => void;
}

/**
 * Subscription information held for a single target
 */
interface SubscriptionInfo {
	targetId: string;
	retryDelay: number;

	/**
	 * QueryIds of all requests made for a subscription on the target
	 */
	queries: Set<string>;

	/**
	 * The subscription actually running.
	 * Undefined if not actively subscribed to target.
	 */
	subscription: Subscription | undefined;
}

const initialRetryDelay = 1000; // 1 second
const maxRetryDelay = 4096 * 1000; // ~68 minutes

export class CombiningSubscriptionManager<TResult = unknown> {
	private readonly logger: ILogger;

	private name: string;

	/**
	 * The individual queries subscribed on the instance
	 */
	private readonly subscriptions = new Map<string, SubscriptionInfo>();

	/**
	 * SubscriptionHandler instance to use for requesting subscribing via SubscriptionManagerService
	 */
	private subscriptionHandler: SubscriptionHandler;

	constructor(
		private readonly config: CombiningSubscriptionManagerConfig<TResult>,
		logger: ILogger,
		private readonly subscriptionService: SubscriptionManagerService
	) {
		this.name = `SubscriptionManager<${config.name}>`;
		this.logger = logger.createScopedLogger(this.name);

		this.subscriptionHandler = subscriptionService.createHandler(
			this.config.name,
			this.config.changeTypes,
			this.config.subscriptionGql,
			(targetId) => this.handleSubscribeToTarget(targetId),
			(targetId, subscription) => this.handleUnsubscribeFromTarget(targetId, subscription)
		);
	}

	/**
	 * Add subscription to a target to the subscription manager
	 */
	public subscribe(queryId: string, targetId: string): void {
		this.addQuery(queryId, targetId);
	}

	/**
	 * Add subscriptions for multiple targets to the subscription manager
	 */
	public subscribeMany(queryId: string, targetIds: string[]): void {
		targetIds.forEach((id) => this.addQuery(queryId, id));
	}

	/**
	 * Subscribe to the given targets and unsubscribe from any other targets previously subscribed to
	 */
	public subscribeToOnly(queryId: string, targetIds: string[]): void {
		const targetIdSet = new Set(targetIds);
		const targetsToUnsubscribe = new Set([...this.subscriptions.keys()].filter((x) => !targetIdSet.has(x)));
		targetIds.forEach((id) => this.addQuery(queryId, id));
		targetsToUnsubscribe.forEach((key) => this.removeQuery(queryId, key));
	}

	/**
	 * Remove a subscription to a target from the subscription manager
	 */
	public unsubscribe(queryId: string, targetId: string): void {
		this.removeQuery(queryId, targetId);
	}

	/**
	 * Unsubscribe all subscriptions placed using a specific queryId
	 */
	public unsubscribeQuery(queryId: string): void {
		this.subscriptions.forEach((subscriptionInfo, key) => {
			if (subscriptionInfo.queries.has(queryId)) {
				this.removeQuery(queryId, key);
			}
		});
	}

	/**
	 * Unsubscribe all subscriptions placed at the subscription manager
	 */
	public unsubscribeAll(): void {
		this.subscriptions.forEach((subscriptionInfo, _) => {
			this.subscriptionService.removeSubscriptionRequest(subscriptionInfo.targetId, this.subscriptionHandler);
		});
		this.subscriptions.clear();
	}

	private addQuery(queryId: string, targetId: string): void {
		// Get/add subscription info for target
		let subscriptionInfo = this.subscriptions.get(targetId);
		if (!subscriptionInfo) {
			subscriptionInfo = {
				targetId: targetId,
				retryDelay: this.config.retryWaitMs ?? initialRetryDelay,
				queries: new Set<string>(),
				subscription: undefined
			};
			this.subscriptions.set(targetId, subscriptionInfo);

			this.subscriptionService.addSubscriptionRequest(targetId, this.subscriptionHandler);
		}
		if (!subscriptionInfo.queries.has(queryId)) {
			// Create query info for specific query on target
			subscriptionInfo.queries.add(queryId);
		}
	}

	private removeQuery(queryId: string, targetId: string): void {
		const subscriptionInfo = this.subscriptions.get(targetId);
		if (subscriptionInfo) {
			subscriptionInfo.queries.delete(queryId);

			if (subscriptionInfo.queries.size === 0) {
				// No more subscribers
				this.subscriptionService.removeSubscriptionRequest(targetId, this.subscriptionHandler);
				this.subscriptions.delete(targetId);
			}
		}
	}

	/**
	 * Handle request from subscription manager service to subscribe to a target
	 */
	private handleSubscribeToTarget(targetId: string): Subscription {
		const subscriptionInfo = this.subscriptions.get(targetId);
		if (!subscriptionInfo) {
			throw new Error('No SubscriptionInfo found for target when requested to subscribe');
		}

		return this.subscribeOnGql(subscriptionInfo);
	}

	/**
	 * Handle request from subscription manager service to unsubscribe from a target
	 */
	private handleUnsubscribeFromTarget(targetId: string, subscription: Subscription): void {
		subscription.unsubscribe();
	}

	private subscribeOnGql(subscriptionInfo: SubscriptionInfo): Subscription {
		const targetId = subscriptionInfo.targetId;

		const subscription = this.config.subscriptionGql
			.subscribe(
				{
					id: targetId,
					types: this.config.changeTypes
				},
				{ errorPolicy: 'all' }
			)
			.subscribe({
				next: (change) => {
					this.logger.trace('GQL', 'Received update', {
						targetId,
						change
					});
					this.handleSubscriptionUpdate(targetId, change);
				},
				error: (error) => {
					this.logger.error('GQL', 'Encountered error', {
						targetId,
						error
					});
					this.handleSubscriptionError(targetId);
				}
			});
		subscriptionInfo.subscription = subscription;

		return subscription;
	}

	private handleSubscriptionUpdate(targetId: string, update: SubscriptionResult<TResult>): void {
		// Reset connection retry timeout value
		const subscriptionInfo = this.subscriptions.get(targetId);
		if (subscriptionInfo) {
			subscriptionInfo.retryDelay = this.config.retryWaitMs ?? initialRetryDelay;
		}

		// Invoke client callback
		if (this.config.onUpdate) {
			try {
				this.config.onUpdate(targetId, update);
			} catch (error) {
				this.logger.error('GQL', 'Client callback threw error', {
					targetId,
					error
				});
			}
		}
	}

	private handleSubscriptionError(targetId: string): void {
		const subscriptionInfo = this.subscriptions.get(targetId);

		// Setup delayed retry of subscription
		const delay = subscriptionInfo ? subscriptionInfo.retryDelay : initialRetryDelay;
		setTimeout(() => {
			this.reCreateSubscription(targetId);
		}, delay);

		if (subscriptionInfo && delay < maxRetryDelay) {
			subscriptionInfo.retryDelay = subscriptionInfo.retryDelay * 2;
		}
	}

	private reCreateSubscription(targetId: string): void {
		const subscriptionInfo = this.subscriptions.get(targetId);
		if (subscriptionInfo && subscriptionInfo.subscription) {
			subscriptionInfo.subscription.unsubscribe();
			this.subscribeOnGql(subscriptionInfo);
		}
	}
}
