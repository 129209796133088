import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApolloModule, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';

import {
	APOLLO_TYPE_POLICY_PROVIDERS,
	ApolloConnectionService,
	SYS_API_URL,
	SysApiHttpConnectionService,
	SysApiMessagePortConnectionService
} from './services';
import { SysApiTypePolicyProvider } from './type-policy/sys-api.type-policy-provider';

@NgModule({
	imports: [ApolloModule],
	providers: [
		{
			provide: APOLLO_NAMED_OPTIONS,
			useFactory(apolloConnectionService: ApolloConnectionService): NamedOptions {
				return { sysApi: apolloConnectionService.createApolloClientOptions() };
			},
			deps: [ApolloConnectionService]
		},
		{
			provide: APOLLO_TYPE_POLICY_PROVIDERS,
			multi: true,
			useClass: SysApiTypePolicyProvider
		}
	]
})
export class SystemApiCoreDataAccessModule {
	public static withHttp(externalConnectInfo: {
		url: string;
		apiKey: string;
	}): ModuleWithProviders<SystemApiCoreDataAccessModule> {
		return {
			ngModule: SystemApiCoreDataAccessModule,
			providers: [
				{
					provide: SYS_API_URL,
					useValue: externalConnectInfo
				},
				{
					provide: ApolloConnectionService,
					useClass: SysApiHttpConnectionService
				}
			]
		};
	}

	public static withMessagePort(): ModuleWithProviders<SystemApiCoreDataAccessModule> {
		return {
			ngModule: SystemApiCoreDataAccessModule,
			providers: [
				{
					provide: ApolloConnectionService,
					useClass: SysApiMessagePortConnectionService
				}
			]
		};
	}
}
