"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationLink = void 0;
const import_apollo_client_1 = require("./import-apollo-client");
const zen_observable_ts_1 = require("zen-observable-ts");
const graphql_1 = require("graphql");
const asynciterable_1 = require("ix/asynciterable");
class ApplicationLink extends import_apollo_client_1.ApolloLink {
    application;
    context;
    execute;
    subscribe;
    schema;
    constructor(application, context) {
        super(undefined);
        this.application = application;
        this.context = context;
        this.execute = application.createExecution();
        this.subscribe = application.createSubscription();
        this.schema = application.schema;
    }
    request(operation) {
        if ((0, graphql_1.getOperationAST)(operation.query, operation.operationName)?.operation === 'subscription') {
            return new zen_observable_ts_1.Observable(observer => {
                let result;
                try {
                    result = this.subscribe({
                        schema: this.schema,
                        document: operation.query,
                        rootValue: undefined,
                        contextValue: this.context,
                        variableValues: operation.variables,
                        operationName: operation.operationName
                    });
                }
                catch (error) {
                    observer.error(error);
                    return;
                }
                const abort = new AbortController();
                const abortSignal = abort.signal;
                result
                    .then(result => {
                    if (Symbol.asyncIterator in result) {
                        if (abortSignal.aborted) {
                            observer.complete();
                        }
                        else {
                            const iterator = result[Symbol.asyncIterator]();
                            abortSignal.addEventListener('abort', () => {
                                iterator
                                    .return?.()
                                    .then(() => observer.complete())
                                    .catch(() => { });
                            });
                            (0, asynciterable_1.from)(iterator)
                                .forEach(result => {
                                observer.next(result);
                            })
                                .then(() => {
                                if (abortSignal.aborted === false) {
                                    observer.complete();
                                }
                            })
                                .catch(reason => observer.error(reason));
                        }
                    }
                    else {
                        observer.next(result);
                        observer.complete();
                    }
                })
                    .catch(reason => observer.error(reason));
                return () => abort.abort();
            });
        }
        return new zen_observable_ts_1.Observable(observer => {
            let result;
            try {
                result = this.execute({
                    schema: this.schema,
                    document: operation.query,
                    rootValue: undefined,
                    contextValue: this.context,
                    variableValues: operation.variables,
                    operationName: operation.operationName
                });
            }
            catch (error) {
                observer.error(error);
                return;
            }
            if ('then' in result) {
                result
                    .then(result => {
                    observer.next(result);
                    observer.complete();
                })
                    .catch(reason => observer.error(reason));
            }
            else {
                observer.next(result);
                observer.complete();
            }
        });
    }
}
exports.ApplicationLink = ApplicationLink;
