import { Inject, Injectable } from '@angular/core';

import { ILogger } from '@shure/shared/angular/utils/logging';

import { DecorationExport } from './decoration.models';
import { DecorationProvider, DECORATION_PROVIDERS } from './decoration.provider';

@Injectable({
	providedIn: 'root'
})
export class DecorationService {
	private readonly logger: ILogger;
	constructor(
		logger: ILogger,
		@Inject(DECORATION_PROVIDERS) private readonly decorationProviders: DecorationProvider[]
	) {
		this.logger = logger.createScopedLogger('DecorationService');
	}

	public async clone(id: string, toId: string): Promise<void> {
		this.logger.trace('clone', '', { id, toId });
		await Promise.all(this.decorationProviders.map((p) => p.decorationClone(id, toId)));
	}

	public async delete(id: string): Promise<void> {
		this.logger.trace('delete', '', { id });
		await Promise.all(this.decorationProviders.map((p) => p.decorationDelete(id)));
	}

	public async get(id: string): Promise<DecorationExport[]> {
		this.logger.trace('get', '', { id });
		const decorations = await Promise.all(this.decorationProviders.map((p) => p.decorationGetExport(id)));
		return decorations.flat();
	}

	public async set(id: string, content: DecorationExport[]): Promise<void> {
		this.logger.trace('set', '', { id, content });
		await Promise.allSettled(content.map((decoration) => this.setDecoration(id, decoration))).then((results) => {
			const rejected = results.find((result) => result.status === 'rejected');
			if (rejected) {
				throw (<PromiseRejectedResult>rejected).reason;
			}
		});
	}

	private async setDecoration(id: string, decoration: DecorationExport): Promise<void> {
		const provider = this.decorationProviders.find((provider) =>
			provider.decorationSupportedTypes().includes(decoration.type)
		);
		if (provider) {
			return provider.decorationSetExport(id, decoration);
		}
		throw new Error(`No decorationProvider could handle decoration type '${decoration.type}'`);
	}
}
