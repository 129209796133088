"use strict";
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/**
 * @typedef {import('@apollo/client/link/core/ApolloLink').ApolloLink} ApolloLink
 */
/**
 * @typedef {import('@apollo/client/link/core/types').Operation} Operation
 */
/**
 * @typedef {import('@apollo/client/link/core/types').FetchResult} FetchResult
 */
/** @type {typeof import('@apollo/client/link/core/ApolloLink').ApolloLink} */
module.exports.ApolloLink = require('@apollo/client/link/core/core.cjs').ApolloLink;
