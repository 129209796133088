import { ToReferenceFunction } from '@apollo/client/cache/core/types/common';
import { ReconcilerFunction } from '@apollo/client/utilities';

type DeepMergeReconcilerContext = [
	{
		toReference: ToReferenceFunction;
	}
];

export const deepMergeReconciler: ReconcilerFunction<DeepMergeReconcilerContext> = function (
	target,
	source,
	property,
	context
) {
	const targetPropertyValue = target[property];
	const sourcePropertyValue = source[property];

	if (!Array.isArray(targetPropertyValue) || !Array.isArray(sourcePropertyValue)) {
		return this.merge(targetPropertyValue, sourcePropertyValue, context);
	}

	if (targetPropertyValue.length === 0) {
		if (sourcePropertyValue.length === 0) {
			// special case, it is supposedly more efficient to return the unmodified target when possible
			return targetPropertyValue;
		}

		return sourcePropertyValue;
	}

	// match items to merge by store object reference if possible, replace target with source if we can't
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const targetItemMap = new Map<string, any>();
	targetPropertyValue
		.filter((targetItem) => this.isObject(targetItem))
		.forEach((targetItem) => {
			const reference = context.toReference(targetItem);
			if (reference !== undefined) {
				targetItemMap.set(reference.__ref, targetItem);
			}
		});

	if (targetItemMap.size === 0) {
		return sourcePropertyValue;
	}

	return sourcePropertyValue.map((sourceItem) => {
		const reference = context.toReference(sourceItem);
		if (reference === undefined) {
			return sourceItem;
		}

		const targetItem = targetItemMap.get(reference.__ref);

		return this.merge(targetItem, sourceItem, context);
	});
};
