import { fromEventPattern } from './fromeventpattern';
import { isFunction } from '../util/isiterable';
function isMessagePortEventEmitter(obj) {
    return !!obj && isFunction(obj.on) && isFunction(obj.off);
}
function isNodeEventEmitter(obj) {
    return !!obj && isFunction(obj.addListener) && isFunction(obj.removeListener);
}
function isEventTarget(obj) {
    return !!obj && isFunction(obj.addEventListener) && isFunction(obj.removeEventListener);
}
/**
 * Converts an event emitter event into an async-iterable stream.
 *
 * @template TSource The type of elements in the emitter stream.
 * @param {EventedTarget} obj The object that emits the events to turn into an async-iterable.
 * @param {string} type The name of the event to listen for creation of the async-iterable.
 * @param {EventListenerOptions} [options] The options for listening to the events such as capture, passive and once.
 * @param {(...args: any[]) => TSource} [resultSelector] The result selector for the event.
 * @returns {AsyncIterableX<TSource>} An async-iterable sequence created from the events emitted from the evented target.
 */
export function fromEvent(obj, type, options, resultSelector) {
    if (isFunction(options)) {
        resultSelector = options;
        options = undefined;
    }
    if (isEventTarget(obj)) {
        const target = obj;
        return fromEventPattern((h) => target.addEventListener(type, h, options), (h) => target.removeEventListener(type, h, options), resultSelector);
    }
    else if (isMessagePortEventEmitter(obj)) {
        const target = obj;
        return fromEventPattern((h) => target.on(type, h), (h) => target.off(type, h), resultSelector);
    }
    else if (isNodeEventEmitter(obj)) {
        const target = obj;
        return fromEventPattern((h) => target.addListener(type, h), (h) => target.removeListener(type, h), resultSelector);
    }
    else {
        throw new TypeError('Unsupported event target');
    }
}


